import React, { useEffect, useState } from "react";
import axiosInstance from "./axiosInstance";
import { Button, Form, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import RunnerCSV from "./RunnerCSV";

const RunnerList = () => {
    const [runners, setRunners] = useState([]);
    const [currentRunner, setCurrentRunner] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [buttonValue, setButtonValue] = useState("Update");
    const openModal = (item) => {
        console.log(item);
        setCurrentRunner(item);
        setShowModal(true);
    }
    const closeModal = () => { setShowModal(false); setFormErrors(null) };

    useEffect(() => {
        axiosInstance.get('/runners/')
            .then(response => {
                setRunners(response.data);
            })
            .catch(error => {
                console.log(error);
            })
    }, [])

    const handleFormSubmit = (e) => {
        e.preventDefault();
        if (currentRunner?.id) {
            axiosInstance.patch(`/runners/${currentRunner.id}/`, currentRunner)
                .then(response => {
                    const updatedRunners = runners.map(runner => {
                        if (runner.id === response.data.id) {
                            return response.data;
                        }
                        return runner;
                    });
                    setRunners(updatedRunners);
                    setCurrentRunner(response.data);
                    setButtonValue("Saved");
                    setTimeout(() => {
                        console.log("revert");
                        setButtonValue('Update');
                    }, 2000);
                })
        } else {
            axiosInstance.post("/runners/", {
                "firstname": currentRunner.firstname,
                "name": currentRunner.name,
                "birth": currentRunner.birth
            })
                .then(response => {
                    setCurrentRunner(response.data);
                    setRunners([...runners, response.data]);
                    setFormErrors(null);
                    setButtonValue("Saved");
                    setTimeout(() => {
                        console.log("revert");
                        setButtonValue('Update');
                    }, 2000);
                })
                .catch(error => {
                    setFormErrors(error.response.data);
                })
        }

    }

    const handleDelete = (e, index) => {
        e.preventDefault();
        const runnerId = runners[index].id;
        axiosInstance.delete(`/runners/${runnerId}/`)
            .then(response => {
                const updatedRunners = [...runners];
                updatedRunners.splice(index, 1);
                setRunners(updatedRunners)
            })
    }

    return (
        <>
            <Button onClick={openModal}>Add</Button>
            <RunnerCSV />
            <Modal show={showModal} onHide={closeModal}>
                <Form onSubmit={handleFormSubmit}>
                    <ModalHeader closeButton>
                    </ModalHeader>
                    <ModalBody>
                        {formErrors?.non_field_errors}
                        <Form.Group>
                            <Form.Select
                                name="gender"
                                isInvalid={formErrors?.gender}
                                value={currentRunner?.gender || ""}
                                onChange={(e) => setCurrentRunner({ ...currentRunner, gender: e.target.value })}
                            >
                                <option value="" disabled>Sexe</option>
                                <option value="F">Femme</option>
                                <option value="M">Homme</option>
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">{formErrors?.gender}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Control
                                name="firstname"
                                placeholder="Firstname"
                                value={currentRunner?.firstname || ""}
                                onChange={(e) => setCurrentRunner({ ...currentRunner, firstname: e.target.value })}
                                isInvalid={formErrors?.firstname}
                            />
                            <Form.Control.Feedback type="invalid">{formErrors?.firstname}</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group>
                            <Form.Control
                                name="name"
                                placeholder="Name"
                                value={currentRunner?.name || ""}
                                onChange={(e) => setCurrentRunner({ ...currentRunner, name: e.target.value })}
                                isInvalid={formErrors?.name}
                            />
                            <Form.Control.Feedback type="invalid">{formErrors?.name}</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group>
                            <Form.Control
                                type="date"
                                name="birth"
                                value={currentRunner?.birth || ""}
                                onChange={(e) => setCurrentRunner({ ...currentRunner, birth: e.target.value })}
                                isInvalid={formErrors?.birth}
                            />
                            <Form.Control.Feedback type="invalid">{formErrors?.birth}</Form.Control.Feedback>
                        </Form.Group>

                    </ModalBody>
                    <ModalFooter>
                        <Button type="submit" variant="primary">{buttonValue}</Button>
                    </ModalFooter>
                </Form>
            </Modal>

            <Table>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Birthday</th>
                        <th>Gender</th>
                    </tr>
                </thead>
                <tbody>
                    {runners.map((item, index) => (
                        <tr key={item.id}>
                            <td><Link onClick={(e) => openModal(item)}>{item.firstname} {item.name}</Link></td>
                            <td>{item.birth}</td>
                            <td>{item.gender}</td>
                            <td><Button onClick={(e) => handleDelete(e, index)}>Delete</Button></td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </>
    )
}

export default RunnerList;