import React, { useState, useEffect } from "react";
import axiosInstance from "./axiosInstance";
import { Button, Col, Form, Row, Table, Modal, ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";
import useWebSocket from 'react-use-websocket';
import { useParams } from "react-router-dom";
import { Typeahead } from 'react-bootstrap-typeahead';
import moment from "moment";

const RaceScan = () => {
    const { id } = useParams();
    const [readers, setReaders] = useState([]);
    const [runners, setRunners] = useState([]);
    const [formErrors, setFormErrors] = useState(null);
    const [race, setRace] = useState(null);
    const [selectedReader, setSelectedReader] = useState(null);

    const [isWebSocketOpen, setIsWebSocketOpen] = useState(false);
    const [socketUrl, setSocketUrl] = useState(null);

    const [newEPC, setNewEPC] = useState(null);
    const [existingEpc, setExistingEpc] = useState(null);

    const [showNewUserModal, setShowNewUserModal] = useState(false);
    const closeNewUserModal = () => { setShowNewUserModal(false); setFormNewUserErrors(null); setCurrentNewUserRunner(null) };
    const [formNewUserErrors, setFormNewUserErrors] = useState({});
    const [currentNewUserRunner, setCurrentNewUserRunner] = useState(null);
    const openNewUserModal = () => {
        setShowNewUserModal(true);
    }

    const { readyState, sendMessage, sendJsonMessage, lastMessage, lastJsonMessage } = useWebSocket(socketUrl, {
        onOpen: () => {
            console.log('WebSocket opened');
            setIsWebSocketOpen(true);
        },
        onClose: () => {
            console.log('WebSocket closed');
            setIsWebSocketOpen(false);
            setSocketUrl(null);
        },

        shouldReconnect: () => true,
    });

    useEffect(() => {
        if (lastJsonMessage) {
            const newScan = lastJsonMessage.message;
            console.log(newScan);
            if (race && race.racepersontag_set.some(item => item.tag?.epc === newScan.epc)) {
                if (existingEpc === null) {
                    console.log("existing");
                    setExistingEpc(newScan.epc);
                    setTimeout(() => {
                        setExistingEpc(null);
                    }, 1000);
                }
            } else if (newEPC === null) {
                const newScan = lastJsonMessage.message;
                setNewEPC(newScan);
            }
        }
    }, [lastJsonMessage]);

    useEffect(() => {
        axiosInstance.get('/readers/')
            .then(response => {
                setReaders(response.data);
            })
            .catch(error => {
                console.log(error);
            })
        axiosInstance.get('/runners/')
            .then(response => {
                setRunners(response.data);
            })

        axiosInstance.get(`/races/${id}/`)
            .then(response => {
                setRace(response.data);
            })
            .catch(error => {
                console.log(error);
            })
    }, [])

    const handleReaderChange = (e) => {
        const reader = readers.find((reader) => reader.id === parseInt(e.target.value));
        if (reader) {
            setSelectedReader(reader);
        } else {
            setSelectedReader(null);
        }
        console.log(selectedReader);
    }

    const handleStartButtonClick = () => {
        if (selectedReader) {
            if (isWebSocketOpen) {
                setSocketUrl(null);
                axiosInstance.patch(`/readers/${selectedReader.id}/start/`, {
                    start_inventory: false,
                    mode: "running",
                    config_duration: 10
                })
            } else {
                axiosInstance.patch(`/readers/${selectedReader.id}/start/`, {
                    start_inventory: true,
                    mode: "registering",
                    config_duration: 1,
                    config_txpower: 10
                })
                    .then((response) => {
                        if (response.data.online) {
                            setSocketUrl(`${process.env.REACT_APP_WS_URL}/api/ws/scan/${selectedReader.id}/`);
                            // setReaderResponse(null);
                        } else {
                            // setReaderResponse('Reader is offline');
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    })
            }
        }
    }

    const handleSaveButtonClick = () => {
        axiosInstance.post(`/racepersontags/`, {
            tag: { 'epc': newEPC.epc },
            race: id,
            runner: newEPC.runner ? newEPC.runner.id : null,
            bib: newEPC.bib || null
        }).then(response => {
            setRace(prevRace => ({
                ...prevRace,
                racepersontag_set: [...prevRace.racepersontag_set, response.data]
            }));
            setNewEPC(null);
            setFormErrors(null);
        }).catch(error => {
            setFormErrors({ "new": error.response.data })
        })
    }

    const handleDeleteRunner = (e, index) => {
        console.log(index);
        const updatedRacePersonTags = [...race.racepersontag_set];
        if (index !== undefined) {
            axiosInstance.delete(`/racepersontags/${race.racepersontag_set[index].id}/`)
                .then(response => {
                    updatedRacePersonTags.splice(index, 1);
                    setRace((prevRace) => ({
                        ...prevRace,
                        racepersontag_set: updatedRacePersonTags
                    }))
                })
                .catch(error => {
                    console.log(error);
                })
        }


    }

    const handleChangeRunner = (e, index) => {
        const updatedRacePersonTags = [...race.racepersontag_set];
        if (e.target && index !== undefined) {
            const { name, value } = e.target;
            updatedRacePersonTags[index][name] = value ? value : null;
        } else {
            if (e.length !== 0) {
                updatedRacePersonTags[index].runner = e[0];
            } else {
                updatedRacePersonTags[index].runner = null;
            }
        }

        setRace((prevRace) => ({
            ...prevRace,
            racepersontag_set: updatedRacePersonTags
        }))
    }

    const handleNewUserFormSubmit = (e) => {
        e.preventDefault();
        axiosInstance.post("/runners/", {
            "gender": currentNewUserRunner.gender,
            "firstname": currentNewUserRunner.firstname,
            "name": currentNewUserRunner.name,
            "birth": currentNewUserRunner.birth
        })
            .then(response => {
                setCurrentNewUserRunner(response.data);
                closeNewUserModal();
                setFormErrors(null);
                axiosInstance.get('/runners/')
                    .then(response => {
                        setRunners(response.data);
                    })
            })
            .catch(error => {
                setFormNewUserErrors(error.response.data);
            })
    }


    const handleSaveRunner = (e, index) => {
        e.preventDefault();
        const updatedRunner = {
            id: race.racepersontag_set[index].id,
            runner: race.racepersontag_set[index].runner ? race.racepersontag_set[index].runner.id : null,
            bib: race.racepersontag_set[index].bib
        }
        axiosInstance.patch(`/racepersontags/${updatedRunner.id}/`, updatedRunner)
            .then(response => {
                const updatedRunner_set = [...race.racepersontag_set];
                updatedRunner_set[index] = response.data;
                setFormErrors(null);
                setRace((prevRace) => ({
                    ...prevRace,
                    racepersontag_set: updatedRunner_set
                }))
            })
            .catch(error => {
                setFormErrors({ [index]: error.response.data });
            })
    }

    return (
        <>
            <Modal show={showNewUserModal} onHide={closeNewUserModal}>
                <Form onSubmit={handleNewUserFormSubmit}>
                    <ModalHeader closeButton>
                    </ModalHeader>
                    <ModalBody>
                        {formErrors?.non_field_errors}
                        <Form.Group>
                            <Form.Select
                                name="gender"
                                isInvalid={formNewUserErrors?.gender}
                                value={currentNewUserRunner?.gender || ""}
                                onChange={(e) => setCurrentNewUserRunner({ ...currentNewUserRunner, gender: e.target.value })}
                            >
                                <option value="" disabled>Sexe</option>
                                <option value="F">Femme</option>
                                <option value="M">Homme</option>
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">{formNewUserErrors?.gender}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Control
                                name="firstname"
                                placeholder="Firstname"
                                value={currentNewUserRunner?.firstname || ""}
                                onChange={(e) => setCurrentNewUserRunner({ ...currentNewUserRunner, firstname: e.target.value })}
                                isInvalid={formErrors?.firstname}
                            />
                            <Form.Control.Feedback type="invalid">{formNewUserErrors?.firstname}</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group>
                            <Form.Control
                                name="name"
                                placeholder="Name"
                                value={currentNewUserRunner?.name || ""}
                                onChange={(e) => setCurrentNewUserRunner({ ...currentNewUserRunner, name: e.target.value })}
                                isInvalid={formNewUserErrors?.name}
                            />
                            <Form.Control.Feedback type="invalid">{formNewUserErrors?.name}</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group>
                            <Form.Control
                                type="date"
                                name="birth"
                                value={currentNewUserRunner?.birth || ""}
                                onChange={(e) => setCurrentNewUserRunner({ ...currentNewUserRunner, birth: e.target.value })}
                                isInvalid={formNewUserErrors?.birth}
                            />
                            <Form.Control.Feedback type="invalid">{formNewUserErrors?.birth}</Form.Control.Feedback>
                        </Form.Group>

                    </ModalBody>
                    <ModalFooter>
                        <Button type="submit" variant="primary">Save</Button>
                    </ModalFooter>
                </Form>
            </Modal>
            <Row>
                <Col>
                    <Form.Select
                        onChange={handleReaderChange}
                    >
                        <option>Select reader</option>
                        {readers.map(reader => (
                            <option key={reader.id} value={reader.id}>
                                {reader.name}
                            </option>
                        ))}
                    </Form.Select>
                </Col>
                <Col>
                    <Button onClick={handleStartButtonClick}>
                        {isWebSocketOpen ? "Stop" : "Start"}
                    </Button>
                </Col>
            </Row>

            <Table>
                <thead>
                    <tr>
                        <th>EPC</th>
                        <th>Date</th>
                        <th>Runner</th>
                        <th>Dossart</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>{race && race.racepersontag_set.map((item, index) =>
                (
                    <React.Fragment key={index}>
                        <tr className={existingEpc === item.tag?.epc ? 'existing-epc' : 'scanned-epc'}>
                            <td >{item.tag?.epc}</td>
                            <td>{moment(item.tag?.created_at).format('DD/MM/Y HH:mm:ss')}</td>
                            <td>
                                <Typeahead
                                    name="runner"
                                    labelKey={(option: runners) => `${option.firstname} ${option.name}`}
                                    options={runners}
                                    id={`runner-${index}`}
                                    onChange={e => handleChangeRunner(e, index)}
                                    selected={item.runner ? [item.runner] : null}
                                />
                                <Button onClick={openNewUserModal} >Add</Button>
                            </td>
                            <td>
                                <Form.Group>
                                    <Form.Control
                                        value={item.bib || null}
                                        name="bib"
                                        onChange={(e) => handleChangeRunner(e, index)}
                                        isInvalid={formErrors && formErrors[index]?.bib}
                                    />
                                    <Form.Control.Feedback type="invalid">{formErrors ? formErrors[index]?.bib : ""}</Form.Control.Feedback>
                                </Form.Group>
                            </td>
                            <td><Button onClick={(e) => handleSaveRunner(e, index)}>Save</Button></td>
                            <td><Button variant="danger" onClick={((e) => handleDeleteRunner(e, index))}>Delete</Button></td>
                        </tr>
                    </React.Fragment>
                ))}
                    {newEPC && (
                        <>
                            <tr style={{ borderTop: "solid 1px" }}>
                                <td>{newEPC?.epc}</td>
                                <td>{newEPC?.first_seen}</td>
                                <td>
                                    <Typeahead
                                        name="runner"
                                        labelKey={(option: runners) => `${option.firstname} ${option.name}`}
                                        options={runners}
                                        id={`runner-new`}
                                        selected={newEPC.runner ? [newEPC.runner] : null}
                                    />
                                    <Button onClick={openNewUserModal}>Add</Button>
                                </td>
                                <td>
                                    <Form.Group>
                                        <Form.Control
                                            defaultValue={newEPC?.bib}
                                            name="bib"
                                            // onChange={handleChange}
                                            isInvalid={formErrors?.new?.bib}

                                        />
                                        <Form.Control.Feedback type="invalid">{formErrors?.new?.bib}</Form.Control.Feedback>
                                    </Form.Group>
                                </td>
                                <td><Button onClick={handleSaveButtonClick}>Save</Button></td>
                            </tr>
                        </>)}
                </tbody>
            </Table>
        </>
    )
}

export default RaceScan;