import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import AuthContext from "./AuthProvider";
import axiosInstance from "./axiosInstance";
import { Accordion, Table } from "react-bootstrap";
import moment from 'moment'
import 'moment-duration-format';


const RaceResult = () => {
    const [race, setRace] = useState({});
    const { id } = useParams();
    const { user } = useContext(AuthContext);

    function sortRacePersonTagSet(race) {
        if (!race || !race.racepersontag_set) {
            return [];
        }

        const sortedRacePersonTagSet = race.racepersontag_set.sort((a, b) => {
            const lastCheckpointA = a.record_set[a.record_set.length - 1];
            const lastCheckpointB = b.record_set[b.record_set.length - 1];

            if (!lastCheckpointA && !lastCheckpointB) return 0;
            if (!lastCheckpointA) return 1;
            if (!lastCheckpointB) return -1;
            if (lastCheckpointA.checkpoint !== lastCheckpointB.checkpoint) {
                return lastCheckpointB.checkpoint - lastCheckpointA.checkpoint;
            }
            const timeA = new Date(lastCheckpointA.time);
            const timeB = new Date(lastCheckpointB.time);
            return timeA - timeB;
        });
        return sortedRacePersonTagSet;
    }

    useEffect(() => {
        axiosInstance.get(`/races/${id}/`)
            .then(response => {
                sortRacePersonTagSet(response.data);
                console.log(response.data);
                setRace(response.data);
            })
            .catch(error => {
                console.log(error);
            })
    }, [id]);


    const diffTime = (a, b) => {
        const timeA = moment(a)
        const timeB = moment(b)
        const duration = moment.duration(timeA.diff(timeB))
        return duration.format("hh:mm:ss", { trim: false })
    }

    return (
        <>
            <h1>{race.name}</h1>
            <h5>{race.date}, {race.lieu}</h5>
            <h6>{race.start && moment(race.start).format("DD/MM/YY à HH:mm:ss")}</h6>
            <Table>
                <thead>
                    <tr>
                        {
                            user?.is_super ? <th>EPC</th> : ""
                        }
                        {/* <th>Place</th> */}
                        <th>Nom</th>
                        <th>Dossard</th>
                        {race.checkpoint_set && race.checkpoint_set.map((checkpoint) => (
                            <th key={checkpoint.id}>{checkpoint.name}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {race.racepersontag_set && race.racepersontag_set.map((item, index) => {
                        const passingTimes = {};
                        item.record_set.forEach((passing) => {
                            if (!passingTimes[passing.checkpoint]) {
                                passingTimes[passing.checkpoint] = [];
                            }
                            passingTimes[passing.checkpoint].push({ 'time': passing.time, 'id': passing.id });
                        });
                        return (((user?.is_super) || item.runner?.name) && (<tr key={index}>
                            {user ? <td>{item.tag?.epc}</td> : ""}
                            <td>{item.runner ? item.runner.firstname : ""} {item.runner ? item.runner.name : ""}</td>
                            <td>{item.bib}</td>
                            {/* <td>{item.category}</td> */}
                            {race.checkpoint_set && race.checkpoint_set.map((checkpoint) => (
                                <td key={checkpoint.id}>
                                    <Accordion flush>
                                        <Accordion.Item eventKey={checkpoint.id}>
                                            <Accordion.Header>
                                                {passingTimes[checkpoint.id] && (
                                                    race.start ? `${diffTime(moment(passingTimes[checkpoint.id][passingTimes[checkpoint.id].length - 1].time), race.start)} (${moment(passingTimes[checkpoint.id][passingTimes[checkpoint.id].length - 1].time).format('HH:mm:ss')})` : moment(passingTimes[checkpoint.id][passingTimes[checkpoint.id].length - 1].time).format('DD/MM/YYYY HH:mm:ss')
                                                )}
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                {
                                                    passingTimes[checkpoint.id] && passingTimes[checkpoint.id].map((time, timeIndex) => (
                                                        <div key={timeIndex}>
                                                            {race.start ? `${diffTime(time.time, race.start)} (${moment(time.time).format("HH:mm:ss")})` : moment(time.time).format("d/m/YY HH:mm:ss")}
                                                        </div>

                                                    ))
                                                }
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </td>
                            )
                            )}
                        </tr>))
                    }
                    )}
                </tbody>
            </Table>
        </>
    )
}

export default RaceResult;